import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'
import Layout from '../components/layout'

const AutorTemplate = ({ data }) => (
    <Layout>
        <div className="px-4">
        <div className="flex flex-wrap">
        
        <div className="w-full md:w-1/4 xl:w-1/4 p-4 text-center text-gray-200">
     
        <Img className="w-5 border-4 border-joker" fixed={data.strapiAutor.autorphoto.childImageSharp.fixed} alt={data.strapiAutor.autorname}/>
        <h2 className="rezension-title dark:text-gray-200">{data.strapiAutor.autorname}</h2>

    </div>
    <div className="w-full md:w-3/4 xl:w-3/4 bg-gray-100 dark:bg-gray-700 p-4 text-left text-gray-700 dark:text-gray-200">
    
    <ReactMarkdown source={data.strapiAutor.bio}
                transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
            />

    </div>
        <h2 className="text-joker font-semibold mt-8 ml-4 dark:text-gray-200">Bücher</h2>
        <div className="w-full p-4 text-left text-gray-700 shadow-sm dark:text-gray-200">
        <ReactMarkdown source={data.strapiAutor.bucher}
                    transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
                />
     </div>
  </div>
  </div>
    </Layout>
  )
  
export default AutorTemplate

export const query = graphql`
  query AutorTemplate($slug: String!) {
    strapiAutor(slug: { eq: $slug }) {
        slug
        autorphoto {
          childImageSharp {
            fixed(width: 250, height: 300) {
                ...GatsbyImageSharpFixed
            }
          }
        }
        bio
        bucher
        autorname
    }
  }
` 